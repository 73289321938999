import React, { useState, useEffect } from 'react';
import { withRouter } from '../services';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Modal, Box, TextField, FormControlLabel, Switch, Checkbox, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { Snackbar, Alert } from '@mui/material';
import { Link, NavigateFunction } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import LogoutIcon from '@mui/icons-material/Logout';
import ReactHtmlParser from 'react-html-parser'; 
import './styles/header.scss';
import logo from '../assets/images/landscapelogo.png';
import { config } from '../config';
import { delay, encrypt, decrypt } from '../services';
import { updateUser, updateToken, updateExpired, updateLogined, updateRememberLogin } from '../redux/actions';
import { Response, User, Token, RememberLogin } from '../types';
import { userApi } from '../api';

type Props = {
  location: Location,
  navigate: NavigateFunction,
	token: string,
  user: User,
	logined: boolean,
  expired: string,
  rememberLogin: RememberLogin,
  updateUser: Function,
  updateToken: Function,
  updateExpired: Function,
  updateLogined: Function,
  updateRememberLogin: Function
}

const Header: React.FC<Props> = (props) => {

	const [drawerOpen, setDrawerOpen] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [isScrolled, setIsScrolled] = useState(false);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState('');
  const [severity, setSeverity] = useState('');
  const [showMessage, setShowMessage] = useState(false);

  const [formData, setFormData] = useState({
    username: props.rememberLogin.remember?props.rememberLogin.username:'',
    password: props.rememberLogin.remember&&props.rememberLogin.password!==''?decrypt(props.rememberLogin.password):'',
    rememberPassword: props.rememberLogin.remember,
  });
  const [registerData, setRegisterData] = useState({
    username: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    email: '',
    country: '',
    city: '',
    agreement: false,
  });
  const [resetData, setResetData] = useState({
    password: '',
    confirmPassword: '',
    token: '',
  });
  const [unsubscribeData, setUnsubscribeData] = useState({
    type: 'MarketWatcher',
    email: ''
  });
  const [notification, setNotification] = useState({
    open: false,
    severity: 'success',
    msg: '',
  });
  const [email, setEmail] = useState('');
  const [formComponent, setFormComponent] = useState('Login');

  const handleOpen = () => setOpen(true);
  const handleClose = () => {setOpen(false);props.navigate('/');}

  // Style for the modal box
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100%',
    bgcolor: '#ffffff',
    color: '#1d1d1d',
    border: '2px solid #f1f1f1',
    borderRadius: '0px',
    boxShadow: 24,
    p: 4,
  };

  const openModal = () => {
    handleOpen();
  }
  
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => { 
    const queryParams = new URLSearchParams(props.location.search); 
    
    if (props.location.pathname === '/login') { 
      const resetParam = queryParams.get('reset')??''; 
      if (resetParam) {
        setResetData(prev => ({ ...prev, token: resetParam}));
        setFormComponent('Reset');
        setOpen(true);
      }
    }
    if (props.location.pathname === '/unsubscribe') { 
      setFormComponent('Unsubscribe');
      setOpen(true);
    } 
    const verify = async () => {
      if (props.location.pathname === '/verify') { 
        const token = queryParams.get('token')??''; 
        try{
          const responseForgetPwd: Response = await userApi.verify(token);
          if(responseForgetPwd.status === 200) {
            setNotification({open: true, severity: 'success', msg: 'Account is verified!'});
          }
        } catch (e: any) {
          setNotification({open: true, severity: 'error', msg: e?.data?.msg??e});
        }
        await delay(5000);
        setNotification({open: false, severity: 'success', msg: ''});
        props.navigate('/');
      } 
    }
    verify();
  }, [props]);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const validateForm = (formData: any) => {
    let errors = "";
    const regex = {
      username: /^[A-Za-z][A-Za-z0-9_]{4,25}$/,
      password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{6,30}$/,
      email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      date: /^\d{4}-\d{2}-\d{2}$/,
    };
  
    // Username validation
    if (formData.username !== undefined && !regex.username.test(formData.username)) {
      errors += 'Username length should between 5 to 25.<br/>';
    }
  
    // Password match validation
    if (formData.password !== undefined && (!formData.password.trim() || formData.password !== formData.confirmPassword)) {
      if (!regex.password.test(formData.password)) {
        errors += 'Password should between 6 to 30 characters and mix with at least one lowercase letter, uppercase letter, number and symbol.<br/>';
      } else {
        errors += 'Passwords do not match.<br/>';
      }
    }
  
    // First and Last name validation
    if (formData.firstName !== undefined && !formData.firstName.trim()) {
      errors += 'First Name cannot be empty.<br/>';
    }
    if (formData.lastName !== undefined && !formData.lastName.trim()) {
      errors += 'Last Name cannot be empty.<br/>';
    }
  
    // Email validation
    if (formData.email !== undefined && !regex.email.test(formData.email)) {
      errors += 'Email is not valid.<br/>';
    }
  
    // Country and City validation
    if (formData.country !== undefined && !formData.country.trim()) {
      errors += 'Country cannot be empty.<br/>';
    }
    if (formData.city !== undefined && !formData.city.trim()) {
      errors += 'City cannot be empty.<br/>';
    }
    if (formData.agreement !== undefined && !formData.agreement) {
      errors += 'Please agree terms and conditions.<br/>';
    }
    if (errors !== "") {
      setMsg(errors);
      setSeverity('error');
      // Show the message
      setShowMessage(true);
      // Hide the message after 5 seconds
      delay(10000).then(()=>setShowMessage(false));
    }
    return errors === "";
  };

  const loginChange = (event: any) => {
    const { name, value, checked, type } = event.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };
  
  const logout = async () => {
    try{
      const responseLogout: Response = await userApi.logout(props.token);
      if(responseLogout.status === 200) {
        props.updateToken('');
        props.updateExpired('');
        props.updateUser(null);
        props.updateLogined(false);
      }
    } catch(e: Response|any) {
      props.updateToken('');
      props.updateExpired('');
      props.updateUser(null);
      props.updateLogined(false);
    }    
  }

  const registerChange = (event: any) => {
    const { name, value, checked, type } = event.target;
    setRegisterData({
      ...registerData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const resetChange = (event: any) => {
    const { name, value } = event.target;
    setResetData({
      ...resetData,
      [name]: value,
    });
  };

  const unsubscribeChange = (event: any) => {
    const { name, value, checked, type } = event.target;
    setUnsubscribeData({
      ...unsubscribeData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const registerSubmit = async (event: any) => {
    event.preventDefault();
    // Handle the registration logic here
    if(validateForm(registerData)) {
      try{
        const responseForgetPwd: Response = await userApi.register(registerData.username,registerData.password,registerData.firstName,registerData.lastName,registerData.email,registerData.country,registerData.city);
        if(responseForgetPwd.status === 200) {
          setMsg('Account registered successfully!');
          setSeverity('success');
          setRegisterData({
            username: '',
            password: '',
            confirmPassword: '',
            firstName: '',
            lastName: '',
            email: '',
            country: '',
            city: '',
            agreement: false,
          });
        }
        // Show the message
        setShowMessage(true);
        // Hide the message after 5 seconds
        await delay(5000);
        setShowMessage(false);
        setFormComponent('Login');
      } catch (e: any) {
        setMsg(e?.data?.msg??e);
        setSeverity('error');
        // Show the message
        setShowMessage(true);
        // Hide the message after 5 seconds
        await delay(5000);
        setShowMessage(false);
      }
    }
  };

  const loginSubmit = async (event: any) => {
    event.preventDefault();
    // Handle form submission logic here
    try{
      const responseLogin: Response = await userApi.login(formData.username,formData.password);
      const token: Token = responseLogin.data;
      props.updateToken(token.token);
      props.updateExpired(token.expired);
      const responseUser: Response = await userApi.user(token.token);
      const user: User = responseUser.data;
      props.updateUser(user);
      props.updateLogined(true);
      if(formData.rememberPassword) {
        const remember = {remember: true, username: formData.username, password: encrypt(formData.password)};
        props.updateRememberLogin(remember);
      }else{
        const remember = {remember: false, username: '', password: ''};
        props.updateRememberLogin(remember);
      }
      handleClose();
    } catch(e: Response|any) {
      if(e.data) {
        setMsg(e.data?.msg??e);
        setSeverity('error');
        setShowMessage(true);
        props.updateToken('');
        props.updateExpired('');
        props.updateUser(null);
        props.updateLogined(false);
        // Hide the message after 5 seconds
        await delay(5000);
        setShowMessage(false);
      }
    }    
  };

  const forgetSubmit = async (event: any) => {
    event.preventDefault();
    if(validateForm({email:email})) {
      // Handle the password reset logic here, typically involving sending an email
      try{
        const responseForgetPwd: Response = await userApi.forgetPwd(email);
        if(responseForgetPwd.status === 200) {
          setMsg('Reset password link was sent to your email! Please click the link within 30 min.');
          setSeverity('success');
        }
      } catch (e: any) {
        if(e?.data?.msg === 'Email is not exist') {
          setMsg("Email doesn't exist in the system! Please input correct email.");
          setSeverity('error');
        } else {
          setMsg(e?.data?.msg??e);
          setSeverity('error');
        }
      }
      // Show the message
      setShowMessage(true);
      // Hide the message after 5 seconds
      await delay(5000);
      setShowMessage(false);
    }
  };

  const unsubscribeSubmit = async (event: any) => {
    event.preventDefault();
    if(validateForm(unsubscribeData)) {
      // Handle the password reset logic here, typically involving sending an email
      try{
        const responseUnsubscribe: Response = await userApi.unSubscribe(unsubscribeData.type, unsubscribeData.email);
        if(responseUnsubscribe.status === 200) {
          setMsg('You have unsubscribed!');
          setSeverity('success');
        }
        setUnsubscribeData({type: 'MarketWatcher',email: ''});
        // Show the message
        setShowMessage(true);
        // Hide the message after 5 seconds
        await delay(5000);
        setShowMessage(false);
        handleClose();
        props.navigate('/');
      } catch (e: any) {
        setMsg(e?.data?.msg??e);
        setSeverity('error');
        setShowMessage(true);
        // Hide the message after 5 seconds
        await delay(5000);
        setShowMessage(false);
      }
    }
  }

  const resetSubmit = async (event: any) => {
    event.preventDefault();
    if(validateForm(resetData)) {
      // Handle the password reset logic here, typically involving sending an email
      try{
        const responseForgetPwd: Response = await userApi.resetPwd(resetData.token, resetData.password);
        if(responseForgetPwd.status === 200) {
          setMsg('Password reset successfully!');
          setSeverity('success');
        }
        // Show the message
        setShowMessage(true);
        // Hide the message after 5 seconds
        await delay(5000);
        setShowMessage(false);
        setFormComponent('Login');
      } catch (e: any) {
        setMsg(e?.data?.msg??e);
        setSeverity('error');
        // Show the message
        setShowMessage(true);
        // Hide the message after 5 seconds
        await delay(5000);
        setShowMessage(false);
      }
    }
  }
  
  const handleClickOnLink = (url: string, flag: boolean) => {
    if(flag) {
      window.open(url, '_target');
    } else {
      window.location.href = url;
    }
  };

  const menu = (
    <>
      <ListItem button component={Link} to="https://www.iceloof.com" onClick={(e: any)=>{e.preventDefault();handleClickOnLink("https://www.iceloof.com", false)}} key={'Home'} className="item">
        <ListItemText primary={'Home'} />
      </ListItem>
      <ListItem button component={Link} to="https://marketwatcher.iceloof.com" onClick={(e: any)=>{e.preventDefault();handleClickOnLink("https://marketwatcher.iceloof.com", false)}} key={'Market Watcher'} className="item">
        <ListItemText primary={'Market Watcher'} />
      </ListItem>
      <ListItem button component={Link} to="https://quant.iceloof.com" onClick={(e: any)=>{e.preventDefault();handleClickOnLink("https://quant.iceloof.com", false)}} key={'Quant Trader'} className="item">
        <ListItemText primary={'Quant Trader'} />
      </ListItem>
      <ListItem button component={Link} to="https://traveladvisory.iceloof.com" onClick={(e: any)=>{e.preventDefault();handleClickOnLink("https://traveladvisory.iceloof.com", false)}} key={'Global Travel Advisory'} className="item">
        <ListItemText primary={'Global Travel Advisory'} />
      </ListItem>
      <ListItem button component="a" href="https://blog.iceloof.com" onClick={(e: any)=>{e.preventDefault();handleClickOnLink("https://blog.iceloof.com", true)}} target="_blank" key={'Blog'} className="item">
        <ListItemText primary={'Blog'} />
      </ListItem>
      <ListItem button component={Link} to="https://www.iceloof.com/about" onClick={(e: any)=>{e.preventDefault();handleClickOnLink("https://www.iceloof.com/about", false)}} key={'About'} className="item">
        <ListItemText primary={'About'} />
      </ListItem>
      <ListItem button component={Link} to="https://www.iceloof.com/contact" onClick={(e: any)=>{e.preventDefault();handleClickOnLink("https://www.iceloof.com/contact", false)}} key={'Contact'} className="item">
        <ListItemText primary={'Contact'} />
      </ListItem>
    </>
  );

  const loginComponent = (
    <>
      <div className="title">Login To <span>Iceloof</span></div>
      <Box component="form" onSubmit={loginSubmit} noValidate>
        {showMessage && (severity === 'success'?(
          <div style={{margin: '0 auto', paddingBottom: '15px', width: '300px'}}>
            <Alert severity='success'>
              {msg}
            </Alert>
          </div>
        ):(
          <div style={{margin: '0 auto', paddingBottom: '15px', width: '300px'}}>
            <Alert severity='error'>
              {msg}
            </Alert>
          </div>
        ))}
        <TextField
          fullWidth
          label="Username"
          name="username"
          size="small"
          color="success"
          value={formData.username}
          onChange={loginChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Password"
          name="password"
          size="small"
          type="password"
          color="success"
          value={formData.password}
          onChange={loginChange}
          margin="normal"
        />
        <FormControlLabel
          control={
            <Switch
              color="success"
              checked={formData.rememberPassword}
              onChange={loginChange}
              name="rememberPassword"
            />
          }
          label="Remember Password"
        />
        <Button
          type="submit"
          fullWidth
          size="small"
          variant="contained"
          color="success"
          sx={{ marginTop: 2 }}
        >
          Login
        </Button>
        <Button
          sx={{ marginTop: 2 }}
          variant="text"
          size="small"
          color="success"
          onClick={()=>setFormComponent('Forget')}
        >
          Forgot Password?
        </Button>
        <Button
          sx={{ marginTop: 2, display: 'block' }}
          variant="text"
          size="small"
          color="success"
          onClick={()=>setFormComponent('Register')}
        >
          Register
        </Button>
      </Box>
    </>
  );

  const forgetComponent = (
    <>
      <div className="title">Reset Password</div>
      <Box component="form" onSubmit={forgetSubmit} noValidate>
        {showMessage && (severity === 'success'?(
          <div style={{margin: '0 auto', paddingBottom: '15px', width: '300px'}}>
            <Alert severity='success'>
              {ReactHtmlParser (msg)}
            </Alert>
          </div>
        ):(
          <div style={{margin: '0 auto', paddingBottom: '15px', width: '300px'}}>
            <Alert severity='error'>
              {ReactHtmlParser (msg)}
            </Alert>
          </div>
        ))}
        <TextField
          fullWidth
          label="Email"
          name="email"
          type="email"
          size="small"
          color="success"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          margin="normal"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          size="small"
          color="success"
          sx={{ marginTop: 2 }}
        >
          Submit
        </Button>
        <Button
          fullWidth
          variant="text"
          size="small"
          color="success"
          sx={{ marginTop: 2 }}
          onClick={()=>setFormComponent('Login')}
        >
          Back to Login
        </Button>
      </Box>
    </>
  );

  const unsubscribeTypes = ['MarketWatcher'];
  const unsubscribeComponent = (
    <>
      <div className="title">Unsubscribe</div>
      <Box component="form" onSubmit={unsubscribeSubmit} noValidate>
        {showMessage && (severity === 'success'?(
          <div style={{margin: '0 auto', paddingBottom: '15px', width: '300px'}}>
            <Alert severity='success'>
              {ReactHtmlParser (msg)}
            </Alert>
          </div>
        ):(
          <div style={{margin: '0 auto', paddingBottom: '15px', width: '300px'}}>
            <Alert severity='error'>
              {ReactHtmlParser (msg)}
            </Alert>
          </div>
        ))}
        <FormControl fullWidth size="small" margin="normal" color="success" variant="outlined"> 
          <InputLabel id="type-select-label">Type</InputLabel> 
          <Select labelId="type-select-label" value={unsubscribeData.type} onChange={unsubscribeChange} name="type" label="Type" > {unsubscribeTypes.map((type) => ( <MenuItem key={type} value={type}> {type} </MenuItem> ))} 
          </Select> 
        </FormControl>
        <TextField
          fullWidth
          label="Email"
          name="email"
          type="email"
          size="small"
          color="success"
          value={unsubscribeData.email}
          onChange={unsubscribeChange}
          margin="normal"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          size="small"
          color="success"
          sx={{ marginTop: 2 }}
        >
          Submit
        </Button>
      </Box>
    </>
  );

  const countries = [ 'Argentina', 'Australia', 'Brazil', 'Canada', 'China', 'France', 'Germany', 'Hong Kong', 'India', 'Indonesia', 'Ireland', 'Israel', 'Italy', 'Japan', 'Malaysia', 'Mexico', 'Netherlands', 'New Zealand', 'Norway', 'Pakistan', 'Philippines', 'Poland', 'Portugal', 'Russia', 'Saudi Arabia', 'Singapore', 'South Africa', 'South Korea', 'Spain', 'Sweden', 'Switzerland', 'Taiwan', 'Thailand', 'Turkey', 'Ukraine', 'United Arab Emirates', 'United Kingdom', 'United States', 'Vietnam', 'Others'];

  const registerComponent = (
    <>
      <div className="title">Register</div>
      <Box component="form" onSubmit={registerSubmit} noValidate>
        {showMessage && (severity === 'success'?(
          <div style={{margin: '0 auto', paddingBottom: '15px', width: '300px'}}>
            <Alert severity='success'>
              {ReactHtmlParser (msg)}
            </Alert>
          </div>
        ):(
          <div style={{margin: '0 auto', paddingBottom: '15px', width: '300px'}}>
            <Alert severity='error'>
              {ReactHtmlParser (msg)}
            </Alert>
          </div>
        ))}
        <TextField
          fullWidth
          label="Username"
          name="username"
          size="small"
          color="success"
          value={registerData.username}
          onChange={registerChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Password"
          name="password"
          type="password"
          size="small"
          color="success"
          value={registerData.password}
          onChange={registerChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Confirm Password"
          name="confirmPassword"
          type="password"
          size="small"
          color="success"
          value={registerData.confirmPassword}
          onChange={registerChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="First Name"
          name="firstName"
          size="small"
          color="success"
          value={registerData.firstName}
          onChange={registerChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Last Name"
          name="lastName"
          size="small"
          color="success"
          value={registerData.lastName}
          onChange={registerChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Email"
          name="email"
          type="email"
          size="small"
          color="success"
          value={registerData.email}
          onChange={registerChange}
          margin="normal"
        />
        <FormControl fullWidth size="small" margin="normal" color="success" variant="outlined"> 
          <InputLabel id="country-select-label">Country</InputLabel> 
          <Select labelId="country-select-label" value={registerData.country} onChange={registerChange} name="country" label="Country" > {countries.map((country) => ( <MenuItem key={country} value={country}> {country} </MenuItem> ))} 
          </Select> 
        </FormControl>
        <TextField
          fullWidth
          label="City"
          name="city"
          size="small"
          color="success"
          value={registerData.city}
          onChange={registerChange}
          margin="normal"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={registerData.agreement}
              onChange={registerChange}
              name="agreement"
              size="small"
              color="success"
            />
          }
          label="I agree to the terms and conditions."
        />
        <Button
          type="submit"
          fullWidth
          size="small"
          color="success"
          variant="contained"
          sx={{ marginTop: 2 }}
        >
          Register
        </Button>
        <Button
          fullWidth
          variant="text"
          size="small"
          color="success"
          sx={{ marginTop: 2 }}
          onClick={()=>setFormComponent('Login')}
        >
          Back to Login
        </Button>
      </Box>
    </>
  );

  const resetComponent = (
    <>
      <div className="title">Reset Password</div>
      <Box component="form" onSubmit={resetSubmit} noValidate>
        {showMessage && (severity === 'success'?(
          <div style={{margin: '0 auto', paddingBottom: '15px', width: '300px'}}>
            <Alert severity='success'>
              {ReactHtmlParser (msg)}
            </Alert>
          </div>
        ):(
          <div style={{margin: '0 auto', paddingBottom: '15px', width: '300px'}}>
            <Alert severity='error'>
              {ReactHtmlParser (msg)}
            </Alert>
          </div>
        ))}
        <TextField
          fullWidth
          label="Password"
          name="password"
          type="password"
          size="small"
          color="success"
          value={resetData.password}
          onChange={resetChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Confirm Password"
          name="confirmPassword"
          type="password"
          size="small"
          color="success"
          value={resetData.confirmPassword}
          onChange={resetChange}
          margin="normal"
        />
        <Button
          type="submit"
          fullWidth
          size="small"
          color="success"
          variant="contained"
          sx={{ marginTop: 2 }}
        >
          Reset
        </Button>
        <Button
          fullWidth
          variant="text"
          size="small"
          color="success"
          sx={{ marginTop: 2 }}
          onClick={()=>setFormComponent('Login')}
        >
          Back to Login
        </Button>
      </Box>
    </>
  );

	return (
    <>
      <div className={`header ${isScrolled || !(config.subdomain==='www'&&(window.location.pathname==='/'||window.location.pathname==='/verify')) ? 'scrolled' : ''}`}>
        <AppBar position="fixed" style={{ maxWidth: 1280, left: '50%', transform: 'translateX(-50%)', background: 'transparent', boxShadow: 'none' }}>
          <Toolbar>
            {isMobile && (
              <IconButton
                className="mobileMenuIcon"
                edge="start"
                color="inherit"
                aria-label="menu"
                style={{ marginRight: '15px' }}
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            )}
            <a href="/" onClick={(e: any)=>{e.preventDefault();handleClickOnLink("https://www.iceloof.com", false)}} style={{ flexGrow: 1, paddingTop: '15px' }}>
              <img src={logo} alt="logo" className="logo" />
            </a>
            {!isMobile && (
              <>
                <List component="nav" style={{ display: 'flex' }}>
                  {menu}
                </List>
                {props.logined?<>Hi&nbsp;<i>{props.user.firstname}</i>&nbsp;<div className="pointer" onClick={()=>logout()}><LogoutIcon /></div></>:<Button size="small" variant="contained" color="success" onClick={()=>{setFormComponent('Login');openModal();}}>Sign In</Button>}
              </>
            )}
          </Toolbar>
        </AppBar>
        <Drawer className={`${config.subdomain === 'marketwatcher'?'dark-bg':''}`} anchor="left" open={drawerOpen} onClose={handleDrawerToggle} style={{ width: 240 }}>
          <div style={{ width: 240, textAlign: 'center' }}>
            <List>
              {menu}
            </List>
            {props.logined?<>Hi&nbsp;<i>{props.user.firstname}</i>&nbsp;<div style={{ marginTop: '12px' }} className="pointer" onClick={()=>logout()}><LogoutIcon /></div></>:<Button size="small" variant="contained" color="success" onClick={()=>openModal()}>Sign In</Button>}
          </div>
        </Drawer>
        <Modal
          open={open}
          onClose={handleClose}
          className="login-modal"
          aria-labelledby="login-modal-title"
          aria-describedby="login-modal-description"
        >
          <Box sx={style}>
            <CloseIcon className="pointer close-btn" onClick={handleClose}/>
            <div className="form">
              {formComponent === 'Login' ? loginComponent : formComponent === 'Forget' ? forgetComponent : formComponent === 'Register' ? registerComponent : formComponent === 'Reset' ? resetComponent : formComponent === 'Unsubscribe' ? unsubscribeComponent: <></>}
            </div>
          </Box>
        </Modal>
      </div>
      <Snackbar open={notification.open} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
        {notification.severity === 'success'?(
          <Alert severity='success' sx={{ width: '100%' }}>
            {notification.msg}
          </Alert>
        ):(
          <Alert severity='error' sx={{ width: '100%' }}>
            {notification.msg}
          </Alert>
        )}
      </Snackbar>
    </>
	);
}

const mapStateToProps = (state: any) => ({
	user: state.user.user,
  token: state.user.token,
	logined: state.user.logined,
  expired: state.user.expired,
  rememberLogin: state.user.rememberLogin
});

const mapDispatchToProps = (dispatch: any) => {
	return {
    updateUser: bindActionCreators(updateUser, dispatch),
    updateToken: bindActionCreators(updateToken, dispatch),
    updateExpired: bindActionCreators(updateExpired, dispatch),
    updateLogined: bindActionCreators(updateLogined, dispatch),
    updateRememberLogin: bindActionCreators(updateRememberLogin, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));