import axios from 'axios';
import { config } from '../config';

export const user = (token: string): any => {
  return new Promise((resolve, reject) => {
    axios
      .get(config.url.API_URL+'user', {headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization':'Bearer '+token }})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response ? err.response : err);
        });
  });
}

export const login = (username: string, password: string): any => {
  return new Promise((resolve, reject) => {
    axios
      .post(config.url.API_URL+'user/auth', 
        { 
          username:username, 
          password:password 
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response ? err.response : err);
        });
  });
}

export const register = (username: string, password: string, firstName: string, lastName: string, email: string, country: string, city: string): any => {
  return new Promise((resolve, reject) => {
    axios
      .post(config.url.API_URL+'user/create', 
        { 
          username:username, 
          password:password,
          firstname:firstName,
          lastname:lastName,
          email:email,
          country:country,
          city:city
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response ? err.response : err);
        });
  });
}

export const verify = (token: string): any => {
  return new Promise((resolve, reject) => {
    axios
      .get(config.url.API_URL+'user/verify?token='+token)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response ? err.response : err);
        });
  });
}

export const logout = (token: string): any => {
  return new Promise((resolve, reject) => {
    axios
      .post(config.url.API_URL+'user/logout', {}, {headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization':'Bearer '+token }})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response ? err.response : err);
        });
  });
}

export const refreshToken = (token: string): any => {
  return new Promise((resolve, reject) => {
    axios
      .post(config.url.API_URL+'user/refresh', {token: token})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response ? err.response : err);
        });
  });
}

export const forgetPwd = (email: string): any => {
  return new Promise((resolve, reject) => {
    axios
      .post(config.url.API_URL+'user/resetPassword', 
        { 
          email:email
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response ? err.response : err);
        });
  });
}

export const resetPwd = (token: string, password: string): any => {
  return new Promise((resolve, reject) => {
    axios
      .post(config.url.API_URL+'user/reset', 
        { 
          token:token,
          password: password
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response ? err.response : err);
        });
  });
}

export const unSubscribe = (type: string, email: string): any => {
  return new Promise((resolve, reject) => {
    axios
      .post(config.url.API_URL+'unsubscribe', {'type': type, 'email': email}, {headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response ? err.response : err);
        });
  });
}

export const userApi = {
  user: user,
  login: login,
  register: register,
  logout: logout,
  verify: verify,
  refreshToken: refreshToken,
  forgetPwd: forgetPwd,
  resetPwd: resetPwd,
  unSubscribe: unSubscribe
}