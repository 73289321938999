import React, { useState, useEffect } from 'react';
import { delay, numberFormat, localTime, utcTime, etTime, utcConvert, getFirstDayOfCurrentMonthInNY, getLastDayOfMarchNY, withRouter } from '../services';
import { connect } from 'react-redux';
import { Grid, Paper, Box, Select, MenuItem, Switch, FormControlLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination } from '@mui/material';
import { Alert } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SettingsIcon from '@mui/icons-material/Settings';
import { styled } from '@mui/material/styles';
import ConfirmDialog from './ConfirmDialog';
import HistoricalChart from './HistoricalChart';
import { quantApi } from '../api';
import { Response, User, TradingAccount, TradingStatus, TradingSystem, TradingStrategy, TradingHolding, TradingTransaction, DailyPl } from '../types';

const Container = styled(Grid)(({ theme }) => ({
	width: '100%',
	maxWidth: '1280px',
	margin: 'auto'
}));


interface Props {
	location: Location,
	token: string,
	user: User,
	logined: boolean,
	expired: string,
}

interface Holding {
	account: string,
	ticker: string,
	price: number,
	amount: number
}

interface Balance {
	date: string,
	balance: number
}

const QuantDashboard: React.FC<Props> = (props) => {
	const [selectedPlatform, setSelectedPlatform] = useState('Total');
	const [tradingAccounts, setTradingAccounts] = useState<TradingAccount[]>([]);
	const [tradingStatus, setTradingStatus] = useState<TradingStatus[]>([]);
	const [tradingSystem, setTradingSystem] = useState<TradingSystem>();
	const [holdings, setHoldings] = useState<TradingHolding[]>([]);
	const [transactions, setTransactions] = useState<TradingTransaction[]>([]);
	const [historicalData, setHostoricalData] = useState<Balance[]>([]);
	const [MTD, setMTD] = useState(0);
	const [YTD, setYTD] = useState(0);
	const [isTradingEnabled, setIsTradingEnabled] = useState(false);
	const [action, setAction] = useState('');
	const [period, setPeriod] = useState('1w');
	const [msg, setMsg] = useState('');
  const [severity, setSeverity] = useState('');
  const [showMessage, setShowMessage] = useState(false);
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [transPage, setTransPage] = useState(0);
	const [totalTrans, setTotalTrans] = useState(0);
	const [openSetting, setOpenSetting] = useState(false);
	const [strategyId, setStrategyId] = useState(0);
	const [strategyName, setStrategyName] = useState('');
  const [strategyParameters, setStrategyParameters] = useState('');
	const [updatemsg, setUpdatemsg] = useState('');
	const [currentUTCTime, setCurrentUTCTime] = useState('');
	const [currentETTime, setCurrentETTime] = useState('');
	const [currentLocalTime, setCurrentLocalTime] = useState('');
	// Define the periods
	const periods = [
		{ label: '1W', value: '1w' },
		{ label: '1M', value: '1m' },
		{ label: '3M', value: '3m' },
		{ label: '6M', value: '6m' },
		{ label: '1Y', value: '1y' },
		{ label: '2Y', value: '2y' },
		{ label: '3Y', value: '3y' },
		{ label: '5Y', value: '5y' },
		{ label: 'Max', value: 'max' },
	];
	// Function to handle platform selection
	const handlePlatformSelect = (e: any) => {
		setSelectedPlatform(e.target.value);
	};

	// Function to toggle trading
	const toggleTrading = () => {
		let system = tradingSystem;
		if(system){
			system.enable = !isTradingEnabled;
			quantApi.updatetradingSystem(props.token, system);
		}
		setIsTradingEnabled(!isTradingEnabled);
	};

	// Function to handle action selection
	const handleActionSelect = (event: any) => {
		const action = event.target.value;
		setAction(action);
		setIsDialogOpen(true);
	};

	const confirmAction = async () => {
		setIsDialogOpen(false);
		let response: Response|undefined;
		try{
			if(action === 'stopTrader') {
				const id = tradingAccounts.filter((account: TradingAccount)=>account.broker.broker===selectedPlatform).at(0)?.id;
				response = await quantApi.stopSystem(props.token, 'trader', id?id:0);
			} else if(action === 'restartTrader') {
				const id = tradingAccounts.filter((account: TradingAccount)=>account.broker.broker===selectedPlatform).at(0)?.id;
				response = await quantApi.restartSystem(props.token, 'trader', id?id:0);
			} else if(action === 'stopGateway') {
				const id = tradingAccounts.filter((account: TradingAccount)=>account.broker.broker===selectedPlatform).at(0)?.id;
				response = await quantApi.stopSystem(props.token, 'gateway', id?id:0);
			} else if(action === 'restartGateway') {
				const id = tradingAccounts.filter((account: TradingAccount)=>account.broker.broker===selectedPlatform).at(0)?.id;
				response = await quantApi.restartSystem(props.token, 'gateway', id?id:0);
			}
		} catch (e) {
			response = undefined;
		}
		if(response !== undefined && response?.status === 200) {
			if(action === 'stopTrader') {
				setMsg("Trader stopped");
			}else if(action === 'restartTrader') {
				setMsg("Trader restarted");
			}if(action === 'stopGateway') {
				setMsg("Gateway stopped");
			}else if(action === 'restartGateway') {
				setMsg("Gateway restarted");
			}
			setSeverity('success');
		} else {
			if(action === 'stopTrader') {
				setMsg("Trader failed to stop");
			}else if(action === 'restartTrader') {
				setMsg("Trader failed to restart");
			}if(action === 'stopGateway') {
				setMsg("Gateway failed to stop");
			}else if(action === 'restartGateway') {
				setMsg("Gateway failed to restart");
			}
			setSeverity('error');
		}
		setShowMessage(true);
		// Hide the message after 5 seconds
		await delay(5000);
		setShowMessage(false);	
	}

	const handlePeriodChange = (period: string) => {
    setPeriod(period);
  };

	const updateSetting = async () => {
		let strategy: TradingStrategy = {id: strategyId, name: strategyName, strategy: strategyParameters};
		const responseStrategy: Response = await quantApi.updateStrategy(props.token, strategy);
		if(responseStrategy.status === 200){
			setUpdatemsg('Updated');
		} else {
			setUpdatemsg(responseStrategy.data.msg);
		}
		await delay(5000);
		setUpdatemsg('');
		setOpenSetting(false);
	}

	// Account
	useEffect(() => {
		const getTradingAccount = async () => {
			if(props.logined){
				try {
					const responseAccount: Response = await quantApi.tradingAccount(props.token);
					const tradingAccounts: TradingAccount[] = responseAccount.data;
					setTradingAccounts(tradingAccounts);
				} catch (e) {
					
				}
			}
		}
		
		getTradingAccount();
	},[props]);

	// Status
	useEffect(() => {
		const getTradingStatus = async () => {
			if(props.logined && tradingAccounts.length>0){
				try {
					const statusPromises = tradingAccounts.filter((account: TradingAccount)=>selectedPlatform==='Total'||account.broker.broker===selectedPlatform).map(async (account: TradingAccount) => {
						const responseStatus: Response = await quantApi.tradingStatus(props.token, account);
						const status: TradingStatus = responseStatus.data;
						return status; 
					});

					const allStatuses = await Promise.all(statusPromises);
					setTradingStatus(allStatuses.flat());
				} catch (e) {
					
				}
			}
		}
		getTradingStatus();
		const interval = setInterval(getTradingStatus, 30000);
		return () => {clearInterval(interval);}
	},[props, selectedPlatform, tradingAccounts]);

	// System
	useEffect(() => {
		const getTradingSystem = async () => {
			if(props.logined && tradingAccounts.length>0){
				try {
					const systemPromises = tradingAccounts.filter((account: TradingAccount)=>account.broker.broker===selectedPlatform).map(async (account: TradingAccount) => {
						const responseSystem: Response = await quantApi.tradingSystem(props.token, account);
						const system: TradingSystem = responseSystem.data;
						return system; 
					});

					const allSystems = await Promise.all(systemPromises);
					const system = allSystems.flat()?.at(0);
					if(system){
						setIsTradingEnabled(system.enable);
					}
					setTradingSystem(system);
				} catch (e) {
					
				}
			}
		}
		getTradingSystem();
		const interval = setInterval(getTradingSystem, 30000); 
		return () => {clearInterval(interval);}
	},[props, selectedPlatform, tradingAccounts]);

	// Strategy
	useEffect(() => {
		const getTradingStrategy = async () => {
			if(props.logined && tradingAccounts.length>0){
				try {
					const strategyPromises = tradingAccounts.filter((account: TradingAccount)=>account.broker.broker===selectedPlatform).map(async (account: TradingAccount) => {
						const responseStrategy: Response = await quantApi.getStrategyByAccount(props.token, account.id);
						const strategy: TradingStrategy = responseStrategy.data;
						return strategy; 
					});

					const allStrategy = await Promise.all(strategyPromises);
					const strategy = allStrategy.flat()?.at(0);
					if(strategy){
						setStrategyId(strategy.id);
						setStrategyName(strategy.name);
						setStrategyParameters(strategy.strategy);
					}
				} catch (e) {
					
				}
			}
		}
		getTradingStrategy();
		const interval = setInterval(getTradingStrategy, 60000*15);
		return () => {clearInterval(interval);}
	},[props, selectedPlatform, tradingAccounts]);

	// Holdings
	useEffect(() => {
		const getTradingHolding = async () => {
			if(props.logined && tradingAccounts.length>0){
				try {
					const holdingPromises = tradingAccounts.filter((account: TradingAccount)=>selectedPlatform==='Total'||account.broker.broker===selectedPlatform).map(async (account: TradingAccount) => {
						const responseStatus: Response = await quantApi.tradingHolding(props.token, account);
						const system: TradingHolding = responseStatus.data;
						return system; 
					});

					const allHoldings = await Promise.all(holdingPromises);
					setHoldings(allHoldings.flat());
				} catch (e) {
					
				}
			}
		}
		getTradingHolding();
		const interval = setInterval(getTradingHolding, 30000); 
		return () => {clearInterval(interval);}
	},[props, selectedPlatform, tradingAccounts]);

	useEffect(() => {
		const getTransactions = async () => {
			if(props.logined && tradingAccounts.length>0){
				try {
					if(selectedPlatform!=='Total'){
						const transPromises = tradingAccounts.filter((account: TradingAccount)=>account.broker.broker===selectedPlatform).map(async (account: TradingAccount) => {
							const responseTrans: Response = await quantApi.transactionsById(props.token, account.id, transPage+1);
							setTotalTrans(responseTrans.data.totalPages);
							const trans: TradingTransaction[] = responseTrans.data.content;
							return trans; 
						});
	
						const allTrans = await Promise.all(transPromises);
						setTransactions(allTrans.flat());
					} else {
						const responseTrans: Response = await quantApi.transactions(props.token, transPage+1);
						setTotalTrans(responseTrans.data.totalPages);
						const trans: TradingTransaction[] = responseTrans.data.content;
						setTransactions(trans);
					}
				} catch (e) {
					
				}
			}
		}
		getTransactions();
		const interval = setInterval(getTransactions, 60000);
		return () => {clearInterval(interval);}
	},[props, selectedPlatform, tradingAccounts, transPage]);

	// Historical Data 
	useEffect(() => {
		const getHistoricalData = async () => {
			if(props.logined && tradingAccounts.length>0){
				try {
					if(selectedPlatform!=='Total'){
						const historyPromises = tradingAccounts.filter((account: TradingAccount)=>account.broker.broker===selectedPlatform).map(async (account: TradingAccount) => {
							const responseHistory: Response = await quantApi.historicalDataByAccount(props.token, account.id, 'BASE', period);
							const history: TradingStatus[] = responseHistory.data;
							return history; 
						});
	
						const allHistory = await Promise.all(historyPromises);
						const dateToBalanceMap: Record<string, number> = {};
						const history: TradingStatus[] = allHistory.flat().sort((a: TradingStatus, b: TradingStatus) => {
							const dateA = new Date(a.date);
							const dateB = new Date(b.date);
							return dateA.getTime() - dateB.getTime();
						});
						history.forEach((item: TradingStatus) => {
							const { date, balance } = item;
							if (dateToBalanceMap[date]) {
								dateToBalanceMap[date] += balance;
							} else {
								dateToBalanceMap[date] = balance;
							}
						});

						// Convert the map to an array of objects
						const result: Balance[] = Object.keys(dateToBalanceMap).map((date) => ({
							date,
							balance: dateToBalanceMap[date],
						}));
						setHostoricalData(result);
					} else {
						const responseHistory: Response = await quantApi.historicalData(props.token, 'BASE', period);
						const allHistory: TradingStatus[] = responseHistory.data;
						const history: TradingStatus[] = allHistory.sort((a: TradingStatus, b: TradingStatus) => {
							const dateA = new Date(a.date);
							const dateB = new Date(b.date);
							return dateA.getTime() - dateB.getTime();
						});
						const dateToBalanceMap: Record<string, number> = {};

						history.forEach((item: TradingStatus) => {
							const { date, balance } = item;
							if (dateToBalanceMap[date]) {
								dateToBalanceMap[date] += balance;
							} else {
								dateToBalanceMap[date] = balance;
							}
						});

						// Convert the map to an array of objects
						const result: Balance[] = Object.keys(dateToBalanceMap).map((date) => ({
							date,
							balance: dateToBalanceMap[date],
						}));
						setHostoricalData(result);
					}
				} catch (e) {
					
				}
			}
		}
		getHistoricalData();
		const interval = setInterval(getHistoricalData, 60000*15); 
		return () => {clearInterval(interval);}
	},[props, selectedPlatform, tradingAccounts, period]);

	// MTD IB, IG, FUND
	useEffect(() => {
		const getDailyPl = async () => {
			if(props.logined && tradingAccounts.length>0){
				const date = getFirstDayOfCurrentMonthInNY();
				try {
					if(selectedPlatform!=='Total'){
						const dailyPlPromises = tradingAccounts.filter((account: TradingAccount)=>account.broker.broker===selectedPlatform).map(async (account: TradingAccount) => {
							const responseDailyPl: Response = await quantApi.getDailyPlByAccount(props.token, account.id, date);
							const dailyPl: DailyPl[] = responseDailyPl.data;
							return dailyPl; 
						});
						const allDailyPl = await Promise.all(dailyPlPromises);
						const dailyPls: DailyPl[] = allDailyPl.flat();
						const IB_MTD_Cash: number[] = dailyPls.filter((daily: DailyPl)=>daily.account==='IB'&&daily.holdingType==='Cash').sort((a: DailyPl, b: DailyPl) => {
							const dateA = new Date(a.date);
							const dateB = new Date(b.date);
							return dateA.getTime() - dateB.getTime();
						}).map((daily: DailyPl)=>daily.balance);
						const IB_MTD_Marketvalue: number[] = dailyPls.filter((daily: DailyPl)=>daily.account==='IB'&&daily.holdingType==='Marketvalue').sort((a: DailyPl, b: DailyPl) => {
							const dateA = new Date(a.date);
							const dateB = new Date(b.date);
							return dateA.getTime() - dateB.getTime();
						}).map((daily: DailyPl)=>daily.balance);
						const IB_MTD_Deposit: number =  dailyPls.filter((daily: DailyPl)=>daily.account==='IB'&&daily.holdingType==='').map((daily: DailyPl)=>daily.balance).reduce((sum, balance) => sum + balance, 0);
						
						const IG_MTD_Cash: number[] = dailyPls.filter((daily: DailyPl)=>daily.account==='IG'&&daily.holdingType==='Cash').sort((a: DailyPl, b: DailyPl) => {
							const dateA = new Date(a.date);
							const dateB = new Date(b.date);
							return dateA.getTime() - dateB.getTime();
						}).map((daily: DailyPl)=>daily.balance);
						const IG_MTD_Marketvalue: number[] = dailyPls.filter((daily: DailyPl)=>daily.account==='IG'&&daily.holdingType==='Marketvalue').sort((a: DailyPl, b: DailyPl) => {
							const dateA = new Date(a.date);
							const dateB = new Date(b.date);
							return dateA.getTime() - dateB.getTime();
						}).map((daily: DailyPl)=>daily.balance);
						const IG_MTD_Deposit: number =  dailyPls.filter((daily: DailyPl)=>daily.account==='IG'&&daily.holdingType==='').map((daily: DailyPl)=>daily.balance).reduce((sum, balance) => sum + balance, 0);
						
						const FUND_MTD_Cash: number[] = dailyPls.filter((daily: DailyPl)=>daily.account==='FUND'&&daily.holdingType==='Cash').sort((a: DailyPl, b: DailyPl) => {
							const dateA = new Date(a.date);
							const dateB = new Date(b.date);
							return dateA.getTime() - dateB.getTime();
						}).map((daily: DailyPl)=>daily.balance);
						const FUND_MTD_Marketvalue: number[] = dailyPls.filter((daily: DailyPl)=>daily.account==='FUND'&&daily.holdingType==='Marketvalue').sort((a: DailyPl, b: DailyPl) => {
							const dateA = new Date(a.date);
							const dateB = new Date(b.date);
							return dateA.getTime() - dateB.getTime();
						}).map((daily: DailyPl)=>daily.balance);
						const FUND_MTD_Deposit: number =  dailyPls.filter((daily: DailyPl)=>daily.account==='FUND'&&daily.holdingType==='').map((daily: DailyPl)=>daily.balance).reduce((sum, balance) => sum + balance, 0);
						
						const IB_MTD_chg = IB_MTD_Cash[IB_MTD_Cash.length-1] - IB_MTD_Cash[0] + IB_MTD_Marketvalue[IB_MTD_Marketvalue.length-1] - IB_MTD_Marketvalue[0] - IB_MTD_Deposit;
						const IG_MTD_chg = IG_MTD_Cash[IG_MTD_Cash.length-1] - IG_MTD_Cash[0] + IG_MTD_Marketvalue[IG_MTD_Cash.length-1] - IG_MTD_Marketvalue[0] - IG_MTD_Deposit;
						const FUND_MTD_chg = FUND_MTD_Cash[FUND_MTD_Cash.length-1] - FUND_MTD_Cash[0] + FUND_MTD_Marketvalue[FUND_MTD_Cash.length-1] - FUND_MTD_Marketvalue[0] - FUND_MTD_Deposit;

						const MTD_chg = IB_MTD_chg||0+IG_MTD_chg||0+FUND_MTD_chg||0;
						setMTD(MTD_chg);
					} else {
						const responseDailyPl: Response = await quantApi.getDailyPl(props.token, date);
						const dailyPls: DailyPl[] = responseDailyPl.data;
						const IB_MTD_Cash: number[] = dailyPls.filter((daily: DailyPl)=>daily.account==='IB'&&daily.holdingType==='Cash').sort((a: DailyPl, b: DailyPl) => {
							const dateA = new Date(a.date);
							const dateB = new Date(b.date);
							return dateA.getTime() - dateB.getTime();
						}).map((daily: DailyPl)=>daily.balance);
						const IB_MTD_Marketvalue: number[] = dailyPls.filter((daily: DailyPl)=>daily.account==='IB'&&daily.holdingType==='Marketvalue').sort((a: DailyPl, b: DailyPl) => {
							const dateA = new Date(a.date);
							const dateB = new Date(b.date);
							return dateA.getTime() - dateB.getTime();
						}).map((daily: DailyPl)=>daily.balance);
						const IB_MTD_Deposit: number =  dailyPls.filter((daily: DailyPl)=>daily.account==='IB'&&daily.holdingType==='').map((daily: DailyPl)=>daily.balance).reduce((sum, balance) => sum + balance, 0);
						
						const IG_MTD_Cash: number[] = dailyPls.filter((daily: DailyPl)=>daily.account==='IG'&&daily.holdingType==='Cash').sort((a: DailyPl, b: DailyPl) => {
							const dateA = new Date(a.date);
							const dateB = new Date(b.date);
							return dateA.getTime() - dateB.getTime();
						}).map((daily: DailyPl)=>daily.balance);
						const IG_MTD_Marketvalue: number[] = dailyPls.filter((daily: DailyPl)=>daily.account==='IG'&&daily.holdingType==='Marketvalue').sort((a: DailyPl, b: DailyPl) => {
							const dateA = new Date(a.date);
							const dateB = new Date(b.date);
							return dateA.getTime() - dateB.getTime();
						}).map((daily: DailyPl)=>daily.balance);
						const IG_MTD_Deposit: number =  dailyPls.filter((daily: DailyPl)=>daily.account==='IG'&&daily.holdingType==='').map((daily: DailyPl)=>daily.balance).reduce((sum, balance) => sum + balance, 0);
						
						const FUND_MTD_Cash: number[] = dailyPls.filter((daily: DailyPl)=>daily.account==='FUND'&&daily.holdingType==='Cash').sort((a: DailyPl, b: DailyPl) => {
							const dateA = new Date(a.date);
							const dateB = new Date(b.date);
							return dateA.getTime() - dateB.getTime();
						}).map((daily: DailyPl)=>daily.balance);
						const FUND_MTD_Marketvalue: number[] = dailyPls.filter((daily: DailyPl)=>daily.account==='FUND'&&daily.holdingType==='Marketvalue').sort((a: DailyPl, b: DailyPl) => {
							const dateA = new Date(a.date);
							const dateB = new Date(b.date);
							return dateA.getTime() - dateB.getTime();
						}).map((daily: DailyPl)=>daily.balance);
						const FUND_MTD_Deposit: number =  dailyPls.filter((daily: DailyPl)=>daily.account==='FUND'&&daily.holdingType==='').map((daily: DailyPl)=>daily.balance).reduce((sum, balance) => sum + balance, 0);

						const IB_MTD_chg = IB_MTD_Cash[IB_MTD_Cash.length-1] - IB_MTD_Cash[0] + IB_MTD_Marketvalue[IB_MTD_Marketvalue.length-1] - IB_MTD_Marketvalue[0] - IB_MTD_Deposit;
						const IG_MTD_chg = IG_MTD_Cash[IG_MTD_Cash.length-1] - IG_MTD_Cash[0] + IG_MTD_Marketvalue[IG_MTD_Cash.length-1] - IG_MTD_Marketvalue[0] - IG_MTD_Deposit;
						const FUND_MTD_chg = IG_MTD_Cash[FUND_MTD_Cash.length-1] - FUND_MTD_Cash[0] + FUND_MTD_Marketvalue[FUND_MTD_Cash.length-1] - FUND_MTD_Marketvalue[0] - FUND_MTD_Deposit;

						const MTD_chg = IB_MTD_chg||0+IG_MTD_chg||0+FUND_MTD_chg||0;
						setMTD(MTD_chg);
					}
				} catch (e) {
					
				}
			}
		}
		getDailyPl();
		const interval = setInterval(getDailyPl, 30000); // Fetch latest news every minute
		return () => {clearInterval(interval);}
	},[props, selectedPlatform, tradingAccounts]);

	// YTD IB, IG, FUND
	useEffect(() => {
		const getDailyPl = async () => {
			if(props.logined && tradingAccounts.length>0){
				const date = getLastDayOfMarchNY();
				try {
					if(selectedPlatform!=='Total'){
						const dailyPlPromises = tradingAccounts.filter((account: TradingAccount)=>account.broker.broker===selectedPlatform).map(async (account: TradingAccount) => {
							const responseDailyPl: Response = await quantApi.getDailyPlByAccount(props.token, account.id, date);
							const dailyPl: DailyPl[] = responseDailyPl.data;
							return dailyPl; 
						});
						const allDailyPl = await Promise.all(dailyPlPromises);
						const dailyPls: DailyPl[] = allDailyPl.flat();
						const IB_YTD_Cash: number[] = dailyPls.filter((daily: DailyPl)=>daily.account==='IB'&&daily.holdingType==='Cash').sort((a: DailyPl, b: DailyPl) => {
							const dateA = new Date(a.date);
							const dateB = new Date(b.date);
							return dateA.getTime() - dateB.getTime();
						}).map((daily: DailyPl)=>daily.balance);
						const IB_YTD_Marketvalue: number[] = dailyPls.filter((daily: DailyPl)=>daily.account==='IB'&&daily.holdingType==='Marketvalue').sort((a: DailyPl, b: DailyPl) => {
							const dateA = new Date(a.date);
							const dateB = new Date(b.date);
							return dateA.getTime() - dateB.getTime();
						}).map((daily: DailyPl)=>daily.balance);
						const IB_YTD_Deposit: number =  dailyPls.filter((daily: DailyPl)=>daily.account==='IB'&&daily.holdingType==='').map((daily: DailyPl)=>daily.balance).reduce((sum, balance) => sum + balance, 0);
						
						const IG_YTD_Cash: number[] = dailyPls.filter((daily: DailyPl)=>daily.account==='IG'&&daily.holdingType==='Cash').sort((a: DailyPl, b: DailyPl) => {
							const dateA = new Date(a.date);
							const dateB = new Date(b.date);
							return dateA.getTime() - dateB.getTime();
						}).map((daily: DailyPl)=>daily.balance);
						const IG_YTD_Marketvalue: number[] = dailyPls.filter((daily: DailyPl)=>daily.account==='IG'&&daily.holdingType==='Marketvalue').sort((a: DailyPl, b: DailyPl) => {
							const dateA = new Date(a.date);
							const dateB = new Date(b.date);
							return dateA.getTime() - dateB.getTime();
						}).map((daily: DailyPl)=>daily.balance);
						const IG_YTD_Deposit: number =  dailyPls.filter((daily: DailyPl)=>daily.account==='IG'&&daily.holdingType==='').map((daily: DailyPl)=>daily.balance).reduce((sum, balance) => sum + balance, 0);
						
						const FUND_YTD_Cash: number[] = dailyPls.filter((daily: DailyPl)=>daily.account==='FUND'&&daily.holdingType==='Cash').sort((a: DailyPl, b: DailyPl) => {
							const dateA = new Date(a.date);
							const dateB = new Date(b.date);
							return dateA.getTime() - dateB.getTime();
						}).map((daily: DailyPl)=>daily.balance);
						const FUND_YTD_Marketvalue: number[] = dailyPls.filter((daily: DailyPl)=>daily.account==='FUND'&&daily.holdingType==='Marketvalue').sort((a: DailyPl, b: DailyPl) => {
							const dateA = new Date(a.date);
							const dateB = new Date(b.date);
							return dateA.getTime() - dateB.getTime();
						}).map((daily: DailyPl)=>daily.balance);
						const FUND_YTD_Deposit: number =  dailyPls.filter((daily: DailyPl)=>daily.account==='FUND'&&daily.holdingType==='').map((daily: DailyPl)=>daily.balance).reduce((sum, balance) => sum + balance, 0);
						
						const IB_YTD_chg = IB_YTD_Cash[IB_YTD_Cash.length-1] - IB_YTD_Cash[0] + IB_YTD_Marketvalue[IB_YTD_Marketvalue.length-1] - IB_YTD_Marketvalue[0] - IB_YTD_Deposit;
						const IG_YTD_chg = IG_YTD_Cash[IG_YTD_Cash.length-1] - IG_YTD_Cash[0] + IG_YTD_Marketvalue[IG_YTD_Cash.length-1] - IG_YTD_Marketvalue[0] - IG_YTD_Deposit;
						const FUND_YTD_chg = FUND_YTD_Cash[FUND_YTD_Cash.length-1] - FUND_YTD_Cash[0] + FUND_YTD_Marketvalue[FUND_YTD_Cash.length-1] - FUND_YTD_Marketvalue[0] - FUND_YTD_Deposit;

						const YTD_chg = IB_YTD_chg||0+IG_YTD_chg||0+FUND_YTD_chg||0;
						setYTD(YTD_chg);
					} else {
						const responseDailyPl: Response = await quantApi.getDailyPl(props.token, date);
						const dailyPls: DailyPl[] = responseDailyPl.data;
						const IB_MTD_Cash: number[] = dailyPls.filter((daily: DailyPl)=>daily.account==='IB'&&daily.holdingType==='Cash').sort((a: DailyPl, b: DailyPl) => {
							const dateA = new Date(a.date);
							const dateB = new Date(b.date);
							return dateA.getTime() - dateB.getTime();
						}).map((daily: DailyPl)=>daily.balance);
						const IB_MTD_Marketvalue: number[] = dailyPls.filter((daily: DailyPl)=>daily.account==='IB'&&daily.holdingType==='Marketvalue').sort((a: DailyPl, b: DailyPl) => {
							const dateA = new Date(a.date);
							const dateB = new Date(b.date);
							return dateA.getTime() - dateB.getTime();
						}).map((daily: DailyPl)=>daily.balance);
						const IB_MTD_Deposit: number =  dailyPls.filter((daily: DailyPl)=>daily.account==='IB'&&daily.holdingType==='').map((daily: DailyPl)=>daily.balance).reduce((sum, balance) => sum + balance, 0);
						
						const IG_MTD_Cash: number[] = dailyPls.filter((daily: DailyPl)=>daily.account==='IG'&&daily.holdingType==='Cash').sort((a: DailyPl, b: DailyPl) => {
							const dateA = new Date(a.date);
							const dateB = new Date(b.date);
							return dateA.getTime() - dateB.getTime();
						}).map((daily: DailyPl)=>daily.balance);
						const IG_MTD_Marketvalue: number[] = dailyPls.filter((daily: DailyPl)=>daily.account==='IG'&&daily.holdingType==='Marketvalue').sort((a: DailyPl, b: DailyPl) => {
							const dateA = new Date(a.date);
							const dateB = new Date(b.date);
							return dateA.getTime() - dateB.getTime();
						}).map((daily: DailyPl)=>daily.balance);
						const IG_MTD_Deposit: number =  dailyPls.filter((daily: DailyPl)=>daily.account==='IG'&&daily.holdingType==='').map((daily: DailyPl)=>daily.balance).reduce((sum, balance) => sum + balance, 0);
						
						const FUND_MTD_Cash: number[] = dailyPls.filter((daily: DailyPl)=>daily.account==='FUND'&&daily.holdingType==='Cash').sort((a: DailyPl, b: DailyPl) => {
							const dateA = new Date(a.date);
							const dateB = new Date(b.date);
							return dateA.getTime() - dateB.getTime();
						}).map((daily: DailyPl)=>daily.balance);
						const FUND_MTD_Marketvalue: number[] = dailyPls.filter((daily: DailyPl)=>daily.account==='FUND'&&daily.holdingType==='Marketvalue').sort((a: DailyPl, b: DailyPl) => {
							const dateA = new Date(a.date);
							const dateB = new Date(b.date);
							return dateA.getTime() - dateB.getTime();
						}).map((daily: DailyPl)=>daily.balance);
						const FUND_MTD_Deposit: number =  dailyPls.filter((daily: DailyPl)=>daily.account==='FUND'&&daily.holdingType==='').map((daily: DailyPl)=>daily.balance).reduce((sum, balance) => sum + balance, 0);
						
						const IB_MTD_chg = IB_MTD_Cash[IB_MTD_Cash.length-1] - IB_MTD_Cash[0] + IB_MTD_Marketvalue[IB_MTD_Marketvalue.length-1] - IB_MTD_Marketvalue[0] - IB_MTD_Deposit;
						const IG_MTD_chg = IG_MTD_Cash[IG_MTD_Cash.length-1] - IG_MTD_Cash[0] + IG_MTD_Marketvalue[IG_MTD_Cash.length-1] - IG_MTD_Marketvalue[0] - IG_MTD_Deposit;
						const FUND_MTD_chg = FUND_MTD_Cash[FUND_MTD_Cash.length-1] - FUND_MTD_Cash[0] + FUND_MTD_Marketvalue[FUND_MTD_Cash.length-1] - FUND_MTD_Marketvalue[0] - FUND_MTD_Deposit;

						const MTD_chg = IB_MTD_chg||0+IG_MTD_chg||0+FUND_MTD_chg||0;
						setYTD(MTD_chg);
					}
				} catch (e) {
					
				}
			}
		}
		getDailyPl();
		const interval = setInterval(getDailyPl, 30000); 
		return () => {clearInterval(interval);}
	},[props, selectedPlatform, tradingAccounts]);

	useEffect(() => {
    const interval = setInterval(() => {
			setCurrentUTCTime(utcTime('ddd DD MMM  HH:mm'));
			setCurrentETTime(etTime('ddd DD MMM  HH:mm'));
			setCurrentLocalTime(localTime('ddd DD MMM  HH:mm'));
		}, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

	const totalMarketvalue = tradingStatus.filter((status: TradingStatus)=>status.currency==='BASE').filter((status: TradingStatus)=>selectedPlatform==='Total'||status.tradingAccount.broker.broker===selectedPlatform).map((status: TradingStatus)=>status.marketvalue).reduce((sum, balance) => sum + balance, 0);
	const totalCash = tradingStatus.filter((status: TradingStatus)=>status.currency==='BASE').filter((status: TradingStatus)=>selectedPlatform==='Total'||status.tradingAccount.broker.broker===selectedPlatform).map((status: TradingStatus)=>status.cash).reduce((sum, balance) => sum + balance, 0);

	const totalRealizedPnl = tradingStatus.filter((status: TradingStatus)=>status.currency==='BASE').filter((status: TradingStatus)=>selectedPlatform==='Total'||status.tradingAccount.broker.broker===selectedPlatform).map((status: TradingStatus)=>status.realizedpnl).reduce((sum, balance) => sum + balance, 0);
	const totalUnrealizedPnl = tradingStatus.filter((status: TradingStatus)=>status.currency==='BASE').filter((status: TradingStatus)=>selectedPlatform==='Total'||status.tradingAccount.broker.broker===selectedPlatform).map((status: TradingStatus)=>status.unrealizedpnl).reduce((sum, balance) => sum + balance, 0);

	const currentAccount = tradingSystem?.tradingAccount;
	const currentRunning = ((Date.now()/1000)-(tradingSystem?tradingSystem.updated:0))<60?'Active':'Inactive';

	const currentHoldings: Holding[] = holdings.map((holding: TradingHolding)=>{
		const holdingObj = JSON.parse(holding.holding??"[]");
		return holdingObj.map((obj: any[])=>{
			return {account: holding.tradingAccount.account, ticker: obj[0], amount: obj[1], price: obj[2]};
		}).flat();
	}).flat();

	const balanceData = {
    labels: historicalData.map((history: Balance)=>history.date),
    datasets: [
      {
        label: 'Balance',
        data: historicalData.map((history: Balance)=>history.balance),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
				pointRadius: 1,
				lineTension: 0.2
      },
    ],
  };
	const options = {
		responsive: true,
		plugins: {
			legend: {
				display: false
			},
			title: {
				display: false,
				text: 'Historical Balance Chart',
			},
			tooltip: {
				callbacks: {
					label: (context: any) => {
						let label = context.dataset.label || '';
						if (label) {
							label += ': ';
						}
						if (context.parsed.y !== null) {
							label += '$' + numberFormat(context.parsed.y,2);
						}
						return label;
					}
				}
			}
		},
		scales: {
			x: {
				ticks: {
					maxTicksLimit: 15, // Set the maximum number of x-axis labels
				},
			},
			y: {
				ticks: {
					// Include a dollar sign in the ticks
					callback: (value: number) => {
						if(value>=1000000000){
							return '$' + numberFormat(value/1000000000,2)+'B';
						}else if(value>=1000000){
							return '$' + numberFormat(value/1000000,2)+'M';
						}else if(value>=1000){
							return '$' + numberFormat(value/1000,2)+'K';
						}else{
							return '$' + numberFormat(value,2);
						}
					}
				}
			}
		}
	};
	return (
		<Container container spacing={2} className="trading-dashboard">
			<Grid item xs={12} sm={8} md={9} lg={10} className="text-left font-22 font-weight-6">
			Quant Trader
			</Grid>
			<Grid item xs={12} sm={4} md={3} lg={2}>
				<Box>
					<Select
						value={selectedPlatform}
						onChange={handlePlatformSelect}
						size="small"
						fullWidth
					>
						<MenuItem value="Total">Total</MenuItem>
						<MenuItem value="Interactive Brokers">IBKR</MenuItem>
						<MenuItem value="IG Trading">IG</MenuItem>
						<MenuItem value="Investment Funds">Investment Funds</MenuItem>
					</Select>
				</Box>
			</Grid>
			<Grid item xs={12}>
				<Paper>
					<Grid container>
						<Grid item xs={12} md={4} className="font-10 text-center padding-5"><b>UTC:</b> <i>{currentUTCTime}</i></Grid>
						<Grid item xs={12} md={4} className="font-10 text-center padding-5"><b>ET:</b> <i>{currentETTime}</i></Grid>
						<Grid item xs={12} md={4} className="font-10 text-center padding-5"><b>Local:</b> <i>{currentLocalTime}</i></Grid>
					</Grid>
				</Paper>
			</Grid>
			
			{/* Tiles for Total Balance, Daily PnL, etc. */}
			{/* Replace with actual data */}
			<Grid item xs={12} md={6} lg={3}>
				<Paper sx={{ bgcolor: 'info.light' }} className="tile" elevation={5}>
					<div className="font-15 font-weight-6">Balance</div> 
					<div className="font-22 font-weight-9 line-height-30 font-style-italic">${numberFormat(totalMarketvalue+totalCash, 2)}</div>
					<div className="font-10 line-height-12 text-left">Marketvalue<div className="float-right">${numberFormat(totalMarketvalue, 2)}</div></div>
					<div className="font-10 line-height-12 text-left">Cash<div className="float-right">${numberFormat(totalCash, 2)}</div></div>
				</Paper>
			</Grid>
			<Grid item xs={12} md={6} lg={3}>
				<Paper sx={{ bgcolor: 'success.light' }} className="tile" elevation={5}>
					<div className="font-15 font-weight-6">PnL</div> 
					<div className="font-22 font-weight-9 line-height-30 font-style-italic">${numberFormat(totalUnrealizedPnl+totalRealizedPnl, 2)}</div>
					<div className="font-10 line-height-12 text-left">Unrealized<div className="float-right">${numberFormat(totalUnrealizedPnl, 2)}</div></div>
					<div className="font-10 line-height-12 text-left">Realized<div className="float-right">${numberFormat(totalRealizedPnl, 2)}</div></div>
				</Paper>
			</Grid>
			<Grid item xs={12} md={6} lg={3}>
				<Paper sx={{ bgcolor: 'warning.main' }} className="tile" elevation={5}>
					<div className="font-15 font-weight-6">MTD PnL</div> 
					<div className="font-22 font-weight-9 line-height-30 font-style-italic">${numberFormat(MTD, 2)}</div>
					<div className="font-10 line-height-12 text-left">&nbsp;</div>
					<div className="font-10 line-height-12 text-left">&nbsp;</div>
				</Paper>
			</Grid>
			<Grid item xs={12} md={6} lg={3}>
				<Paper sx={{ bgcolor: 'secondary.light' }} className="tile" elevation={5}>
					<div className="font-15 font-weight-6">YTD PnL</div> 
					<div className="font-22 font-weight-9 line-height-30 font-style-italic">${numberFormat(YTD, 2)}</div>
					<div className="font-10 line-height-12 text-left">&nbsp;</div>
					<div className="font-10 line-height-12 text-left">&nbsp;</div>
				</Paper>
			</Grid>
			{/* Repeat for other tiles */}
			
			{/* Panels for current holdings and controller */}
			<Grid item xs={12} md={selectedPlatform==='Total'?12:8}>
				<Paper elevation={3}>
					<div className="holding-header">Current Holdings</div>
					{/* List holdings here */}
					<div className="holding-list">
						<Table sx={{ minWidth: '480px' }}>
							<TableHead>
								<TableRow>
									<TableCell>Account</TableCell>
									<TableCell>Ticker</TableCell>
									<TableCell className="text-right">Avg Price</TableCell>
									<TableCell className="text-right">Amount</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{currentHoldings.map((holding: Holding, index: number)=>
									<TableRow key={index}>
										<TableCell>{holding.account}</TableCell>
										<TableCell>{holding.ticker==='BUY'?'Long NQ FUTURE':holding.ticker==='SELL'?'Short NQ FUTURE':holding.ticker}</TableCell>
										<TableCell className="text-right">${numberFormat(holding.price,2)}</TableCell>
										<TableCell className="text-right">{numberFormat(holding.amount,2)}</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</div>
				</Paper>
			</Grid>
			<Grid item xs={12} md={4} sx={{display:selectedPlatform==='Total'?'none':''}}>
				<Paper className="padding-20 text-left" elevation={3}>
					{/* Add account number, switch button, etc. */}
					<SettingsIcon className="setting" onClick={()=>setOpenSetting(true)} />
					<div className="font-10">Account: {currentAccount?.account}</div>
					<div className="font-10 padding-top-10">Running: <span className={currentRunning==='Active'?'font-weight-6 fg-positive':'font-weight-6 fg-negative'}>{currentRunning}</span></div>
					<FormControlLabel
						control={<Switch size="small" checked={isTradingEnabled} onChange={toggleTrading} />}
						label="Enabled"
					/>
					{showMessage && (severity === 'success'?(
						<div style={{margin: '0 auto', paddingBottom: '15px', width: '300px'}}>
							<Alert severity='success'>
								{msg}
							</Alert>
						</div>
					):(
						<div style={{margin: '0 auto', paddingBottom: '15px', width: '300px'}}>
							<Alert severity='error'>
								{msg}
							</Alert>
						</div>
					))}
					{selectedPlatform==='Interactive Brokers'?
					<Select
						defaultValue="Select Action"
						value="Select Action"
						onChange={handleActionSelect}
						fullWidth
						size="small"
					>
						<MenuItem value="Select Action">Select Action</MenuItem>
						<MenuItem value="stopTrader">Stop Trader</MenuItem>
						<MenuItem value="restartTrader">Restart Trader</MenuItem>
						<MenuItem value="stopGateway">Stop Gateway</MenuItem>
						<MenuItem value="restartGateway">Restart Gateway</MenuItem>
					</Select>
					:<Select
						defaultValue="Select Action"
						value="Select Action"
						onChange={handleActionSelect}
						fullWidth
						size="small"
					>
						<MenuItem value="Select Action">Select Action</MenuItem>
						<MenuItem value="stopTrader">Stop Trader</MenuItem>
						<MenuItem value="restartTrader">Restart Trader</MenuItem>
					</Select>}
				</Paper>
			</Grid>

			<Grid item xs={12}>
				<Paper elevation={3}>
					<div className="history-header">{period.toUpperCase()} Performance</div>
					<div className="history-chart">
						<HistoricalChart data={balanceData} options={options} period={period} periods={periods} handlePeriodChange={handlePeriodChange} type="Line" />
					</div>
				</Paper>
			</Grid>
			{/* Table of transactions */}
			<Grid item xs={12}>
				<Paper elevation={3}>
					<div className="transaction-header">Transactions</div>
					<div className="transaction-table">
					<TableContainer sx={{ minWidth: '680px' }}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Account</TableCell>
									<TableCell>Ticker</TableCell>
									<TableCell>Buy/Sell</TableCell>
									<TableCell className="text-right">Price</TableCell>
									<TableCell className="text-right">Amount</TableCell>
									<TableCell className="text-right">Time</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{transactions.map((trans: TradingTransaction, index: number)=>
									<TableRow key={index}>
										<TableCell>{trans.tradingAccount.account}</TableCell>
										<TableCell>{trans.action==="Open"?(trans.ticker==='BUY'?'Long ND FUTURE':trans.ticker==='SELL'?'Short ND FUTURE':''):trans.action==="Close"?(trans.ticker==='BUY'?'Short ND FUTURE':trans.ticker==='SELL'?'Long ND FUTURE':''):trans.ticker}</TableCell>
										<TableCell>{trans.action==="Open"?'BUY':trans.action==="Close"?'SELL':trans.action}</TableCell>
										<TableCell className="text-right">${numberFormat(trans.price, 2)}</TableCell>
										<TableCell className="text-right">{numberFormat(trans.amount, 2)}</TableCell>
										<TableCell className="text-right">{utcConvert(trans.time,'America/New_York','yyyy-MM-dd HH:mm:ss')}</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
						<TablePagination
							component="div"
							rowsPerPageOptions={[]}
							count={totalTrans}
							rowsPerPage={10}
							page={transPage}
							onPageChange={(e, p)=>setTransPage(p)}
							// Add pagination properties here
						/>
					</TableContainer>
					</div>
				</Paper>
			</Grid>
			<ConfirmDialog
        open={isDialogOpen}
        title="Confirm Action"
        message={"Are you sure you want to "+(action==='stopTrader'?'STOP Trader':action==='restartTrader'?'RESTART Trader':action==='stopGateway'?'STOP Gateway':action==='restartGateway'?'RESTART Gateway':'')+"?"}
        onConfirm={confirmAction}
        onCancel={()=>setIsDialogOpen(false)}
      />
			<Dialog open={openSetting} onClose={()=>setOpenSetting(false)}>
				<DialogTitle>Edit Strategy</DialogTitle>
				<div className={`text-center ${updatemsg==='Updated'?'fg-positive':'fg-negative'}`}>{updatemsg}</div>
				<DialogContent>
					<TextField
						autoFocus
						margin="dense"
						id="name"
						label="Strategy Name"
						type="text"
						size="small"
						fullWidth
						variant="outlined"
						value={strategyName}
						onChange={(e) => setStrategyName(e.target.value)}
					/>
					<TextField
						margin="dense"
						id="parameters"
						label="Strategy Parameters"
						multiline
						rows={4}
						size="small"
						fullWidth
						variant="outlined"
						value={strategyParameters}
						onChange={(e) => setStrategyParameters(e.target.value)}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={()=>setOpenSetting(false)}>Cancel</Button>
					<Button onClick={updateSetting}>Update</Button>
				</DialogActions>
			</Dialog>
		</Container>
	);
}

const mapStateToProps = (state: any) => ({
	user: state.user.user,
	token: state.user.token,
	logined: state.user.logined,
	expired: state.user.expired
});

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QuantDashboard));
